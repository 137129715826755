import Logo from '../Logo';
import style from './Header.module.scss';

const Header = ({ href }) => (
  <header className={style.header} role="banner">
    <a href={href}>
      <Logo />
    </a>
  </header>
);

export default Header;
