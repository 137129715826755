export const QueueLimitTypes = {
  ABSOLUTE_VALUE: 'absolute',
  PER_WORKER: 'perOnlineWorker',
};

export const DashBoardViewStates = {
  DASHBOARD: 'dashboard',
  CHAT: 'chat',
  LIVE_CHATS: 'live-chats',
  ADMIN: 'admin',
};

export const AdminPanelViewStates = {
  OVERVIEW: 'overview',
  HOURS: 'hours',
  QUEUE: 'queue',
  USER_MANAGEMENT: 'users',
  SHORTCUTS: 'shortcuts',
  BLOCKED: 'blocked',
  FLAGGED: 'flagged',
};

export const AdminSettingsKeys = {
  OPENING_HOURS: 'hours',
  SHORTCUTS: 'shortcuts',
  QUEUE_SIZE: 'queue',
  FEATURE_TOGGLES: 'feature_toggles',
};

export const ChatMetaStatus = {
  WRAPPING_UP: 'wrapping up',
  DELETING_UNANSWERED: 'deleting unanswered chat',
};
