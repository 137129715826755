import classNames from 'classnames/bind';
import style from './Grid.module.scss';

const Grid = ({ background, isSpaced, demarcated, gridType, children }) => {
  const cx = classNames.bind(style);
  const className = cx({
    grid: true,
    background: background === 'grey',
    gridSpaced: isSpaced === true,
    gridDemarcated: demarcated === true,
    gridWeighted: gridType === 'weighted',
    gridWeightedImage: gridType === 'weighted-image',
    gridBalanced: gridType === 'balanced',
    gridBalancedLarge: gridType === 'balanced-large',
  });

  return <div className={className}>{children}</div>;
};

export default Grid;
