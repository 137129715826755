import PropTypes from 'prop-types';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';

const QueueConfirmation = ({
  isConfirmed,
  queueLength,
  isTimeoutVisible,
  handleConfirmation,
}) => (
  <Box
    position="absolute"
    bottom="0"
    width="100%"
    backgroundColor="yellow.50"
    h="167px"
    pt="23px"
    pb="32px"
    px="26px"
  >
    <Stack alignItems="center">
      <Text fontWeight="bold" fontSize="14px" textAlign="center">
        You are currently{' '}
        <Text as="span" fontSize="16px" color="brand.plum.500">
          {queueLength}th{' '}
        </Text>
        in the queue.
      </Text>
      {isConfirmed ? (
        <Text textAlign="center" color="brand.teal.500" fontWeight="bold">
          Thank you for confirming!
        </Text>
      ) : (
        <Box height="" />
      )}
      {isTimeoutVisible ? (
        <Flex>
          <Box width="226px">
            <Text fontSize="14px" fontWeight="bold">
              Are you still waiting to chat?
            </Text>
            <Text fontSize="14px">
              Confirm that you‘re here to let us know you‘d still like to speak
              to a support worker.
            </Text>
          </Box>
          <Button
            backgroundColor="brand.teal.500"
            _hover={{ bg: 'brand.teal.600' }}
            color="white"
            size="md"
            onClick={handleConfirmation}
          >
            I‘m here
          </Button>
        </Flex>
      ) : (
        <Text fontSize="14px" textAlign="center">
          We may occasionally ask you to let us know you‘re still here and
          waiting.
        </Text>
      )}
    </Stack>
  </Box>
);

export default QueueConfirmation;

QueueConfirmation.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  queueLength: PropTypes.number.isRequired,
  isTimeoutVisible: PropTypes.bool.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
};
