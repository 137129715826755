const fetchStatus = async (testing) => {
  const res = await fetch(`/api/status${testing ? '?testing=true' : ''}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const json = await res.json();

  return json;
};

export default fetchStatus;
