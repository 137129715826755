import style from './InfoLink.module.scss';

const InfoLink = ({ href, title }) => (
  <span className={style.infoLink}>
    <a href={href}>{title}</a>
    <span />
  </span>
);

export default InfoLink;
