import PropTypes from 'prop-types';

import { Button, Text } from '@chakra-ui/react';

const Exit = ({ handleExit }) => (
  <Button
    onClick={handleExit}
    position="fixed"
    bottom="50%"
    right="0"
    zIndex="999"
    align="center"
    bg="brand.yellow"
    rounded="0"
    px="30px"
    py="10px"
    transform="rotate(90deg)"
    transformOrigin="right top 0"
  >
    <Text color="black" textTransform="uppercase">
      Exit site
    </Text>
  </Button>
);

export default Exit;

Exit.propTypes = {
  handleExit: PropTypes.func.isRequired,
};
