const initialMessages = [
  'Thanks for reaching out today...',
  'Taking the first step to talk to someone can be hard but you’ve definitely come to the right place...',
  'Women contact us for lots of different reasons and every situation is different...',
  'By trusting your instincts and getting in touch, we can start to help...',
  'We do get very busy, but please keep the chat window open and one of our support workers will be with you as soon as they can...',
  'If you’re unable to wait at the moment, please close the chat window and try again later.',
];

export default initialMessages;
