import classNames from 'classnames/bind';
import style from './Container.module.scss';

const Container = ({ blur, children }) => {
  const cx = classNames.bind(style);
  const className = cx({
    container: true,
    blur,
  });

  return (
    <main id="main" role="main">
      <div className={className}>{children}</div>
    </main>
  );
};

export default Container;
