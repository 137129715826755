const fetchClientToken = async (user = null) => {
  const res = await fetch('/api/client-token', {
    method: 'POST',
    body: JSON.stringify({ user }),
  });

  if (!res.ok) {
    throw new Error(`Error code: ${res.status} \nMessage: ${res.statusText}`);
  }

  const json = await res.json();
  const { token } = json;

  return token;
};

export default fetchClientToken;
