import classNames from 'classnames/bind';
import style from './Row.module.scss';

const cx = classNames.bind(style);

const Row = ({ stripeColour, children }) => {
  const className = cx({
    rowWhite: stripeColour === 'white',
  });

  return stripeColour ? (
    <div className={className}>
      <div>{children}</div>
    </div>
  ) : (
    <div className={style.row}>{children}</div>
  );
};

export default Row;
