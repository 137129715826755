import style from './ContactItem.module.scss';

const ContactItem = ({ title, description, isMail, children }) => (
  <div className={style.contactItem}>
    <h3>{title}</h3>
    <p>{description}</p>
    {isMail ? (
      <svg width="32" height="22" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="nonzero" fill="none">
          <path
            d="M25.877 0H3.604C1.616 0 0 1.585 0 3.535v14.93C0 20.415 1.616 22 3.604 22h22.267c1.988 0 3.604-1.585 3.604-3.535V3.54C29.481 1.591 27.865 0 25.877 0z"
            fill="#F7E5F0"
          />
          <path
            d="M27.877 0H5.604C3.616 0 2 1.585 2 3.535v14.93C2 20.415 3.616 22 5.604 22h22.267c1.988 0 3.604-1.585 3.604-3.535V3.54C31.481 1.591 29.865 0 27.877 0zm1.958 18.465c0 1.059-.878 1.92-1.958 1.92H5.604c-1.08 0-1.957-.861-1.957-1.92V3.54c0-1.059.878-1.92 1.957-1.92h22.267c1.08 0 1.958.861 1.958 1.92v14.924h.006zm-9.246-7.65l7.209-6.34a.8.8 0 00.06-1.143.839.839 0 00-1.164-.06l-9.941 8.75-1.94-1.698c-.006-.006-.012-.012-.012-.018a1.213 1.213 0 00-.134-.114L6.775 3.266a.834.834 0 00-1.165.066.796.796 0 00.068 1.142l7.294 6.394-7.264 6.67a.8.8 0 00-.037 1.142.834.834 0 001.165.042l7.374-6.765 2 1.753a.832.832 0 001.098-.006l2.055-1.807 7.331 6.831c.159.15.366.221.567.221a.83.83 0 00.598-.25.8.8 0 00-.03-1.143l-7.24-6.741z"
            fill="#B6006C"
          />
        </g>
      </svg>
    ) : (
      ''
    )}
    {children}
  </div>
);

export default ContactItem;
