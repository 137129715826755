import * as Sentry from '@sentry/node';

export const putRequestOptions = (requestBody) =>
  requestOptions('PUT', requestBody);

export const postRequestOptions = (requestBody) =>
  requestOptions('POST', requestBody);

export const deleteRequestOptions = () => requestOptions('DELETE');

export const handleJsonApiResponse = (response, propertyName = 'response') =>
  checkForSuccess(response)
    .then((apiResponse) => apiResponse.json())
    .then((json) => {
      if (propertyName === null) {
        return json;
      }

      if (propertyName in json) {
        return json[propertyName];
      }

      const loggedResponse = `\r\nRESPONSE:\r\n${JSON.stringify(
        json,
        null,
        2
      )}`;
      Sentry.captureMessage(
        `Property "${propertyName}" not found in json response, returning parent. ${loggedResponse}`
      );

      return json;
    })
    .catch(logAndReThrow);

const requestOptions = (method, jsonBody) => ({
  method,
  ...(jsonBody
    ? {
        body: JSON.stringify(jsonBody),
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      }
    : {}),
});

const checkForSuccess = async (response) => {
  if (!response.ok) {
    const headers = {};
    response.headers.forEach((value, key) => (headers[key] = value));

    const apiError = {
      status: response.status,
      statusText: response.statusText,
      responseHeaders: headers,
      responseBody: await response.json(),
    };

    throw apiError;
  }

  return response;
};

const logAndReThrow = (err) => {
  Sentry.captureException(new Error(err.responseBody.error));
  throw err;
};

const tryParseJson = (possibleJson) => {
  try {
    return JSON.parse(possibleJson);
  } catch (e) {
    return { unparsableContent: possibleJson };
  }
};
