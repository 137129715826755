import { Button } from '@chakra-ui/react';

const ButtonStyled = ({ inverse, ...props }) => (
  <Button
    bg={!inverse ? 'brand.plum.500' : 'brand.teal.500'}
    color="white"
    _hover={{ bg: !inverse ? 'brand.teal.500' : 'brand.plum.500' }}
    transition=".3s"
    {...props}
  />
);

export default ButtonStyled;
