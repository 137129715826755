import style from './Info.module.scss';

const Info = ({ title, description, children }) => (
  <div className={style.info}>
    <h3>{title}</h3>
    <p>{description}</p>
    {children}
  </div>
);

export default Info;
