const sendAutoMessage = async ({ conversation, message }) => {
  const res = await fetch('/api/send-message', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      conversation,
      message,
    }),
  });

  const json = await res.json();
  const { status } = json;

  return status;
};

export default sendAutoMessage;
