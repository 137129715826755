const log = (type, message, object) => {
  // if (process.env.NODE_ENV !== 'development') return
  if (
    type !== 'get' &&
    type !== 'response' &&
    type !== 'error' &&
    type !== 'start' &&
    type !== 'listen' &&
    type != 'event'
  )
    return console.error('logging error');

  let symbol;

  switch (type) {
    case 'get':
      symbol = '⏱';
      break;
    case 'start':
      symbol = '✨';
      break;
    case 'response':
      symbol = '✅';
      break;
    case 'event':
      symbol = '🔶';
      break;
    case 'listen':
      symbol = '📌';
      break;
    case 'error':
      symbol = '❌';
      break;
    default:
      break;
  }

  if (object) return console.info(`${symbol} ${message}`, object);

  return console.info(`${symbol} ${message}`);
};

export default log;
