import * as Sentry from '@sentry/node';
import { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { Client } from '@twilio/conversations';
import {
  Box as ChatWindow,
  Box,
  Button,
  Heading,
  Flex,
  Text,
  Divider,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import beforeunloadRequest from 'beforeunload-request';
import Container from '../css-module-components/Container';
import Header from '../css-module-components/Header';
import Banner from '../css-module-components/Banner';
import Explainer from '../css-module-components/Explainer';
import Cta from '../css-module-components/Cta';
import VectorImage from '../css-module-components/VectorImage';
import Row from '../css-module-components/Row';
import Grid from '../css-module-components/Grid';
import Info from '../css-module-components/Info';
import InfoLink from '../css-module-components/InfoLink';
import Contact from '../css-module-components/Contact';
import ContactItem from '../css-module-components/ContactItem';
import Panel from '../css-module-components/Panel';
import BannerImage from '../css-module-components/BannerImage';
import Layout from '../components/Layout';
import ChatWidget from '../components/Chat/Survivor/ChatWidget';
import QueueConfirmation from '../components/QueueConfirmation';
import ExitSite from '../components/UI/ExitSite';
import sendAutoMessage from '../utils/api/automated-messages/sendAutoMessage';
import fetchClientToken from '../utils/api/token/fetchClientToken';
import { createTask } from '../utils/api/tasks';
import fetchStatus from '../utils/api/status/fetchStatus';
import fetchAddressStatus from '../utils/api/address/fetchAddressStatus';
import parseMessages from '../utils/helpers/parseMessages';
import isSurvivor from '../utils/helpers/isSurvivor';
import * as gtag from '../lib/gtag';
import fetchAddress from '../utils/api/address/fetchAddress';
import initialMessages from '../data/initialMessages';
import log from '../utils/helpers/log';
import { useRouter } from 'next/router';
import { ChatMetaStatus } from '../constants';

const user = `Survivor_${nanoid()}`;

const MessageList = dynamic(
  () => import('../components/Chat/Common/MessageList'),
  {
    ssr: false,
  }
);

const Home = () => {
  const [openingTimes, setOpeningTimes] = useState({
    monday: {
      enabled: true,
      start: '--:--',
      end: '--:--',
    },
    tuesday: {
      enabled: true,
      start: '--:--',
      end: '--:--',
    },
    wednesday: {
      enabled: true,
      start: '--:--',
      end: '--:--',
    },
    thursday: {
      enabled: true,
      start: '--:--',
      end: '--:--',
    },
    friday: {
      enabled: true,
      start: '--:--',
      end: '--:--',
    },
    saturday: {
      enabled: true,
      start: '--:--',
      end: '--:--',
    },
    sunday: {
      enabled: true,
      start: '--:--',
      end: '--:--',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [statusMessage, setStatusMessage] = useState(
    'The current average wait time is 25 minutes.'
  );
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [queueLength, setQueueLength] = useState(6);
  // Queue confirmation is currently not in use
  const [isQueueConfirmationOpen, setIsQueueConfirmationOpen] = useState(false);
  const [isTimeoutVisible, setIsTimeoutVisible] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isChatActivated, setIsChatActivated] = useState(false);
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef(messages);
  const [message, setMessage] = useState('');
  const [activeConversation, setActiveConversation] = useState(null);
  const activeConversationRef = useRef(activeConversation);
  const [activeSupportWorker, setActiveSupportWorker] = useState(null);
  const [supportWorkerLeft, setSupportWorkerLeft] = useState(false);
  const [activeClient, setActiveClient] = useState(null);
  const [userTyping, setUserTyping] = useState(false);
  const [escapeBufferIsActive, setEscapeBufferIsActive] = useState(false);
  const [lastConsumedMessageIndex, setLastConsumedMessageIndex] = useState({
    identity: '',
    index: 0,
  });

  const router = useRouter();
  const nonSystemMessages = messages.filter((m) => m.author !== 'system');
  const getIsChatActive = () => {
    return nonSystemMessages.length > 0 && !supportWorkerLeft;
  };
  const getTextareaMessage = () => {
    if (supportWorkerLeft) return 'Chat ended';
    else if (nonSystemMessages.length > 0) return '';
    return 'Please wait for a support worker';
  };
  const textareaMessage = getTextareaMessage();
  const isChatActive = getIsChatActive();

  const typingStarted = (member) => {
    if (!isSurvivor(member.state.identity)) {
      setUserTyping(true);
      setMessages((prevState) => [
        ...prevState,
        {
          author: `${process.env.NEXT_PUBLIC_SUPPORT_WORKER}_`,
          body: 'typing',
        },
      ]);
    }
  };

  const typingEnded = async (member) => {
    if (!isSurvivor(member.state.identity)) {
      setUserTyping(false);
      const newMessages = messagesRef?.current.filter(
        (msg) => msg.body !== 'typing'
      );
      setMessages(newMessages);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') sendMessage(e);
    if (e.key === 'Escape') {
      escapeBufferIsActive
        ? handleExit('escape key')
        : setEscapeBufferIsActive(true);
    }
  };

  const removed = (event) => {
    log('event', 'removed', event);
    setSupportWorkerLeft(true);
    activeConversationRef.current = null;
  };

  const participantLeft = (event) => {
    log('event', 'member left', event);
    if (isChatActive) {
      log('event', 'member left, setting worker left and chat active to false');
      setSupportWorkerLeft(true);
    }
  };

  const participantUpdated = (event) => {
    if (
      event.participant.attributes.status === ChatMetaStatus.WRAPPING_UP ||
      event.participant.attributes.status === ChatMetaStatus.DELETING_UNANSWERED
    ) {
      log('event', 'Wrapping up or deleting unanswered');
      setSupportWorkerLeft(true);
    }

    if (
      event.participant.identity !== event.participant.conversation.createdBy
    ) {
      setLastConsumedMessageIndex({
        identity: event.participant.identity,
        index: event.participant.lastReadMessageIndex,
      });
    }
  };

  const updateIndex = () => {
    if (document.visibilityState === 'visible') {
      if (activeConversationRef.current) {
        activeConversationRef.current.getParticipants().then((participants) => {
          participants.forEach(async (participant) => {
            if (!isSurvivor(participant.identity)) {
              await activeConversationRef.current.updateLastReadMessageIndex(
                participant.lastReadMessageIndex
              );
              setLastConsumedMessageIndex({
                identity: participant.identity,
                index: participant.lastReadMessageIndex,
              });
            }
          });
        });
      }
    }
  };

  const enableStart = () => {
    // eslint-disable-next-line spaced-comment
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (!isIE) {
      setIsChatActivated(true);
    } else {
      setStatusMessage('browser not supported');
    }
  };

  const messageAdded = async (newMessage) => {
    const messageCount = newMessage.index;
    if (
      messageCount === initialMessages.length &&
      newMessage.author !== newMessage.conversation.createdBy &&
      newMessage.author !== 'system'
    ) {
      log('event', 'GA logging first message received');
      gtag.event({
        action: 'first_message_received',
        category: 'Chat',
        label: 'first message received',
      });
    }

    if (newMessage.author !== newMessage.conversation.createdBy) {
      setActiveSupportWorker(newMessage.author);
    }

    const parsedMessage = parseMessages([newMessage])[0];

    // add new message to message history
    setMessages((prevState) => [...prevState, parsedMessage]);

    // set read message to message index
    if (document.visibilityState === 'visible') {
      await newMessage.conversation.updateLastReadMessageIndex(
        newMessage.index
      );
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (activeConversation && message.trim() !== '') {
      activeConversation.sendMessage(message);
      setMessage('');
    } else {
      setError(true);
    }
  };

  const handleOnChange = (e) => {
    setMessage(e.target.value);
    if (activeConversation) activeConversation.typing();
  };

  const exitPage = () => {
    window.location.replace('https://www.google.co.uk/');
  };

  const handleExit = async (element) => {
    // eslint-disable-next-line no-undef
    window.localStorage.clear();
    gtag.event({
      action: 'end_chat',
      category: 'Chat',
      label: element,
    });
    // shutdown client listeners and redirect URL
    if (activeClient) {
      await activeClient.shutdown();
    }
    exitPage();
  };

  const makeUnLoadRequest = async (
    taskSid,
    conversationSid,
    conversationOwnerSid
  ) => {
    // if task is unassigned, delete task and remove channel, else leave channel
    log('event', 'unload request');
    beforeunloadRequest(
      `/api/unload?taskSid=${taskSid}&conversationSid=${conversationSid}&participantSid=${conversationOwnerSid}`
    );
    gtag.event({
      action: 'close_chat',
      category: 'Chat',
      label: 'unload',
    });
  };

  const setupUnloadHandler = async (
    taskSid,
    conversationSid,
    conversationOwnerSid
  ) => {
    /**
     * @wip Target safari browsers
     * to be removed prior to initial testing
     * https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html#//apple_ref/doc/uid/TP40006511-SW5
     */
    if (
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1
    ) {
      log('event', 'Set pagehide event');
      window.addEventListener('pagehide', async (event) => {
        if (!event.persisted) {
          await makeUnLoadRequest(
            taskSid,
            conversationSid,
            conversationOwnerSid
          );
        }
      });
    } else {
      // set up unload listener
      log('event', 'Set beforeunload event');
      window.addEventListener('beforeunload', async () => {
        await makeUnLoadRequest(taskSid, conversationSid, conversationOwnerSid);
      });
    }
  };

  const conversationJoined = async (conversation) => {
    log('event', 'conversation joined', conversation);

    setActiveConversation(conversation);
    activeConversationRef.current = conversation;

    // set event listeners
    conversation
      .on('messageAdded', messageAdded)
      .on('typingStarted', typingStarted)
      .on('typingEnded', typingEnded)
      .on('participantUpdated', participantUpdated)
      .on('participantLeft', participantLeft);

    log('get', 'creating task');
    log('get', 'fetching conversation owner', conversation);

    const [task, conversationOwner] = await Promise.all([
      createTask(conversation.sid),
      conversation.getParticipantByIdentity(conversation.createdBy),
    ]);
    log('response', 'task created', task);

    await setupUnloadHandler(task.sid, conversation.sid, conversationOwner.sid);
    await sendAutomatedMessages(conversation);
    setIsLoading(false);
  };

  const processAutoMessages = async (sid) => {
    const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    for (const messageToSend of initialMessages) {
      await sendAutoMessage({ conversation: sid, message: messageToSend });
      await timeout(10000);
    }
  };

  const sendAutomatedMessages = async (conversation) => {
    log('event', 'Send automated messgages', conversation.attributes);
    if (conversation && !conversation.attributes.autoMessageStatus) {
      try {
        await processAutoMessages(conversation.sid);
        await conversation.updateAttributes({
          ...conversation.attributes,
          autoMessageStatus: true,
        });
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            fnName: '/pages/index.jsx@sendAutomatedMessages()',
            message: 'Error while sending introductory messages',
          },
        });

        log('error', 'Introductory messages error');
      }
    }
  };

  const createConversation = async (client, address) => {
    log('event', 'creating conversation', client);
    try {
      const uuid = nanoid();
      return await client.createConversation({
        uniqueName: uuid,
        friendlyName: 'Survivor Live Chat',
        isPrivate: true,
        attributes: {
          autoMessageStatus: false,
          address,
        },
      });
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          fnName: '/pages/index.jsx@createConversation()',
        },
      });
      return e;
    }
  };

  const startNewChat = async () => {
    log('start', 'starting chat');
    try {
      const ipAddress = await fetchAddress();
      const token = await fetchClientToken(user);

      log('get', 'create chat client');
      const client = new Client(token, { logLevel: 'warn' });
      setActiveClient(client);
      log('response', 'chat client created', client);

      // set event listener
      client
        .on('tokenAboutToExpire', () => refreshToken(client))
        .on('conversationJoined', conversationJoined);

      log('get', 'create conversation');
      const newConversation = await createConversation(client, ipAddress);
      log('response', 'conversation created', newConversation);

      return newConversation.join();
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          fnName: '/pages/index.jsx@startNewChat()',
        },
      });
    }
  };

  const refreshToken = async (client) => {
    log('event', 'token about to expire');
    try {
      const newToken = await fetchClientToken(user);
      const tokenResponse = await client.updateToken(newToken);
      log('response', 'token refreshed');
    } catch (e) {
      log('error', 'failed to update token', e);
    }
  };

  const activate = async () => {
    setIsLoading(true);
    const status = await fetchStatus(router.query.testing);

    const isBlockedIp = await fetchAddressStatus();
    if (status.isOnline && !status.isBusy && isBlockedIp === 'allowed') {
      await startNewChat();
      setIsChatOpen(true);
      gtag.event({
        action: 'start_chat',
        category: 'Chat',
        label: '',
      });
    } else {
      setStatusMessage(status.isBusy ? 'Service busy' : 'Service offline');
      gtag.event({
        action: 'chat_offline',
        category: 'Chat',
        label: '',
      });
      setIsLoading(false);
    }
  };

  const getOpeningTimes = async () => {
    try {
      const response = await fetch('/api/openingHours');
      const { data } = await response.json();
      return JSON.parse(data);
    } catch (error) {
      console.log('Could not get opening times');
    }
  };

  useEffect(() => {
    if (activeSupportWorker) {
      log('event', 'setting on removed event');
      const removedEvent = (event) => removed(event);
      activeConversation.on('removed', removedEvent);
    }
  }, [activeSupportWorker]);

  useEffect(() => {
    (async () => {
      if (router.isReady) {
        const status = await fetchStatus(router.query.testing);
        if (!status.isOnline) setStatusMessage('Service offline');
        if (status.isBusy) setStatusMessage('Service busy');

        if (status && status.isOnline && !status.isBusy) enableStart();
        const openingTimes = await getOpeningTimes();
        setOpeningTimes(openingTimes);
        // return function cleanup() {
        //   if (activeClient) activeClient.shutdown();
        // };
      }
    })();
  }, [router.isReady]);

  useEffect(() => {
    document.addEventListener('visibilitychange', updateIndex);
    return () => {
      document.removeEventListener('visibilitychange', updateIndex);
    };
  }, []);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const handleConfirmation = () => {
    setIsConfirmed(true);
    setIsTimeoutVisible(false);
  };

  return (
    <Layout noIndex={false}>
      {isChatOpen ? (
        <>
          <Container blur={isChatOpen}>
            <Row>
              <Grid gridType="balanced">
                <BannerImage src="assets/womens-aid-banner.jpg" />
                <Banner />
              </Grid>
            </Row>
          </Container>
          <ChatWindow
            margin="0"
            align="center"
            width={['100%', '420px', '420px', '420px']}
            height={['100%', '512px', '512px', '512px']}
            minHeight={['-webkit-fill-available', 'unset', 'unset', 'unset']}
            position="fixed"
            bottom="0"
            right={[0, '38px', '72px', '98px']}
            bg="gray.50"
          >
            <Box
              alignItems="center"
              as="header"
              backgroundColor="brand.plum.600"
              display="flex"
              justifyContent="space-between"
              p="14px"
            >
              <div>
                <Box as="span" mr=".5rem">
                  💬
                </Box>
                <Heading as="h1" color="white" fontSize="1rem" display="inline">
                  WA Live Chat
                </Heading>
              </div>
              <Button
                bg="brand.yellow"
                onClick={() => handleExit('widget exit button')}
                _hover={{ bg: 'brand.orange' }}
              >
                Clear chat and exit &nbsp; &#10005;
              </Button>
            </Box>
            <MessageList
              maxHeight={['calc(100vh - 146px)', '366px', '366px', '366px']}
              isMe={isSurvivor}
              messages={messages}
              userTyping={userTyping}
              lastConsumedMessageIndex={lastConsumedMessageIndex}
              identity={activeConversation && activeConversation.createdBy}
              supportWorkerLeft={supportWorkerLeft}
              isSurvivor
            />
            {isQueueConfirmationOpen ? (
              <QueueConfirmation
                isConfirmed={isConfirmed}
                queueLength={queueLength}
                isTimeoutVisible={isTimeoutVisible}
                handleConfirmation={handleConfirmation}
              />
            ) : (
              <ChatWidget
                handleOnChange={handleOnChange}
                handleOnKeyDown={handleOnKeyDown}
                message={message}
                sendMessage={sendMessage}
                isChatActive={isChatActive && !supportWorkerLeft}
                textareaMessage={textareaMessage}
              />
            )}
          </ChatWindow>
        </>
      ) : (
        <Container blur={isChatOpen}>
          <ExitSite handleExit={() => handleExit('page exit button')} />
          <Row>
            <Header href="https://www.womensaid.org.uk/" />
          </Row>
          <Row>
            <Grid gridType="balanced">
              <BannerImage src="assets/womens-aid-banner.jpg" />
              <Banner>
                <h2>
                  If something doesn’t feel right in your relationship, it
                  probably isn’t
                </h2>
                <p>
                  If your behaviour has changed because of how your partner
                  treats you or your children, this can be the sign of an
                  unhealthy or controlling relationship.
                </p>
                <p>
                  We know talking to someone else about your personal life can
                  be hard, but getting in touch with us can be your first and
                  most important step.
                </p>
              </Banner>
            </Grid>
          </Row>
          <Row>
            <Grid gridType="balanced">
              <Explainer>
                <Heading as="h3">
                  When you contact us, we promise we will
                </Heading>
                <ul>
                  <li>Never judge you or what you say</li>
                  <li>
                    Always have a fully trained female support worker available
                  </li>
                  <li>Give you space to explore your options</li>
                  <li>
                    Support you to make safe choices for you and your children
                  </li>
                  <li>
                    Keep everything you tell us{' '}
                    <a href="https://www.womensaid.org.uk/terms-conditions/live-chat-safeguarding-confidentiality/">
                      confidential
                    </a>
                  </li>
                </ul>
              </Explainer>
              <Panel>
                <p>
                  <b>Women’s Aid is not an emergency service.</b>
                </p>
                <p>
                  If you think you might be in danger, call the police
                  immediately on <a href="tel:999">999</a>.
                </p>
              </Panel>
            </Grid>
          </Row>
          <Row>
            <Grid gridType="balanced" background="grey">
              <Cta>
                <VectorImage isOnline={isChatActivated} />
                <Button
                  backgroundColor="brand.plum.500"
                  color="white"
                  onClick={activate}
                  _hover={{ bg: 'brand.teal.500' }}
                  disabled={!isChatActivated || isLoading}
                  p="15px"
                  transition=".3s"
                  w="100%"
                  borderRadius="1.5rem"
                  mb="2rem"
                  mt="1rem"
                  isLoading={isLoading}
                >
                  Start Chat
                </Button>
                <Text textAlign="left" fontWeight="bold" mb="3">
                  Opening hours
                </Text>
                <Box textAlign="left">
                  {Object.entries(openingTimes).map(([key, value]) => (
                    <Box key={key}>
                      <Flex
                        w="100%"
                        alignItems="center"
                        justify="space-between"
                        mb="1"
                      >
                        <Text textTransform="capitalize" fontSize="sm">
                          {key}
                        </Text>
                        {value.enabled ? (
                          <Text fontSize="sm">
                            {value.start} - {value.end}
                          </Text>
                        ) : (
                          <Text fontSize="sm">Unavailable</Text>
                        )}
                      </Flex>
                      <Divider my="3" />
                    </Box>
                  ))}
                </Box>
                <Text my="2rem">{statusMessage}</Text>
              </Cta>
              <Contact>
                <ContactItem
                  title="Live Chat FAQs"
                  description="We have created this set of Frequently Asked Questions to help you navigate using the Live Chat."
                >
                  <InfoLink
                    href="https://www.womensaid.org.uk/information-support/live-chat-faqs/"
                    title="View our Live Chat FAQs page"
                  />
                </ContactItem>
                <ContactItem
                  title="Other ways to contact us"
                  description="Our live chat can get quite busy but please do keep trying. You can also send an email."
                  isMail
                >
                  <InfoLink
                    href="https://www.womensaid.org.uk/information-support/help-by-email/"
                    title="Get help by email"
                  />
                </ContactItem>
                <ContactItem
                  title="Other ways to get help"
                  description="We’ve gathered some useful links to websites and organisations who can provide you with information and support."
                >
                  <InfoLink
                    href="https://www.womensaid.org.uk/information-support/useful-links/"
                    title="View our useful links page"
                  />
                </ContactItem>
              </Contact>
            </Grid>
          </Row>
          <Row>
            <Grid gridType="balanced" isSpaced>
              <Info
                title="Keeping your online searches private"
                description="If you want to cover your tracks online, we can tell you how to clear your browser history on our help page."
              >
                <InfoLink
                  href="https://www.womensaid.org.uk/cover-your-tracks-online/"
                  title="Read our help page"
                />
              </Info>
              <Info
                title="Talking to other women in a safe space"
                description="Our Survivors’ Forum is a safe and anonymous space for women to share their experiences and support one another."
              >
                <InfoLink
                  href="https://survivorsforum.womensaid.org.uk/"
                  title="Go to forum"
                />
              </Info>
            </Grid>
          </Row>
          <Row>
            <Grid gridType="balanced" isSpaced>
              <Info
                title="How to find more supportive information"
                description="You can find advice on housing, child contact or legal rights, by reading our Survivor’s Handbook."
              >
                <InfoLink
                  href="https://www.womensaid.org.uk/the-survivors-handbook/"
                  title="Read the Survivor's Handbook"
                />
              </Info>
              <Info
                title="What to do if you’re worried about someone"
                description="If you’re worried that a friend or someone in your family might be in an unhealthy, controlling or abusive relationship, the best way to get support is by phone or email."
              >
                <InfoLink
                  href="https://www.womensaid.org.uk/the-survivors-handbook/im-worried-about-someone-else/"
                  title="How to help someone else"
                />
              </Info>
            </Grid>
          </Row>
          <Row>
            <Grid gridType="balanced" isSpaced>
              <Info
                title="What is domestic abuse?"
                description="Domestic abuse is an incident or pattern of incidents of controlling, coercive, threatening, degrading or violent behaviour, including sexual violence. In most cases, this is carried out by a partner, ex-partner, a family member or a carer."
              >
                <InfoLink
                  href="https://www.womensaid.org.uk/information-support/what-is-domestic-abuse/"
                  title="Read more about domestic abuse"
                />
              </Info>
            </Grid>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Home;
