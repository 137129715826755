import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  InputGroup,
  InputRightElement,
  Textarea,
} from '@chakra-ui/react';
import ButtonStyled from '../../../UI/ButtonStyled';

const ChatWidget = ({
  isChatActive,
  handleOnChange,
  handleOnKeyDown,
  message,
  sendMessage,
  textareaMessage,
}) => (
  <Box as="form" position="absolute" bottom="0" width="100%" bg="white">
    <Stack>
      <InputGroup size="lg">
        <Textarea
          minHeight="60px"
          fontSize="16px"
          paddingTop="15px"
          paddingBottom="15px"
          lineHeight="1.7"
          isDisabled={!isChatActive}
          onChange={(e) => handleOnChange(e)}
          onKeyDown={(e) => handleOnKeyDown(e)}
          placeholder="Type your message here..."
          value={textareaMessage || message}
          resize="none"
          margin={0}
          rounded="0"
          pr="100px"
          zIndex="2"
        />
        <InputRightElement width="100px" height="100%">
          <ButtonStyled
            isDisabled={!isChatActive || message.trim() === ''}
            margin={0}
            onClick={(e) => sendMessage(e)}
            size="md"
          >
            Send
          </ButtonStyled>
        </InputRightElement>
      </InputGroup>
    </Stack>
  </Box>
);

export default ChatWidget;

ChatWidget.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  handleOnKeyDown: PropTypes.func.isRequired,
  isChatActive: PropTypes.bool.isRequired,
  textareaMessage: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  sendMessage: PropTypes.func.isRequired,
};
