import PropTypes from 'prop-types';
import Head from 'next/head';
import { Flex } from '@chakra-ui/react';

const Layout = ({ title, children, description, author, noIndex }) => (
  <>
    <Head>
      <title>{`${title} | Women's Aid Live Chat`}</title>
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      {noIndex && <meta name="robots" content="noindex" />}
      <link rel="icon" href="/favicon.ico" />
    </Head>
    <Flex as="main" flexDirection="column" height="100%">
      {children}
    </Flex>
  </>
);

export default Layout;

Layout.defaultProps = {
  title: 'Live Chat',
  description: `Women's Aid's live chat service lets women chat directly with a support worker`,
  author: '@womensaid',
  noIndex: true,
};

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  noIndex: PropTypes.bool,
};
