import {
  handleJsonApiResponse,
  postRequestOptions,
  putRequestOptions,
  deleteRequestOptions,
} from '../../helpers/api';

export const createTask = (conversationSid) =>
  fetch('/api/task', postRequestOptions({ conversationSid })).then(
    handleJsonApiResponse
  );

export const deleteTask = (query, src) =>
  fetch(`/api/task?taskId=${query}&src=${src}`, deleteRequestOptions()).then(
    (response) => handleJsonApiResponse(response, 'task')
  );

export const getTask = (query) =>
  fetch(`/api/task?taskId=${query}`).then((response) =>
    handleJsonApiResponse(response, 'task')
  );

export const getTasksByStatus = (assignmentStatus) =>
  fetch(`/api/task?assignmentStatus=${assignmentStatus}`).then((response) =>
    handleJsonApiResponse(response, 'tasks')
  );

export const updateTask = (taskSid, attributes, assignmentStatus = undefined) =>
  fetch(
    `/api/task?taskId=${taskSid}`,
    putRequestOptions({ attributes, assignmentStatus })
  ).then(handleJsonApiResponse);
