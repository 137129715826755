const fetchAddress = async () => {
  const res = await fetch('/api/address', {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const json = await res.json();

  return json.address;
};

export default fetchAddress;
