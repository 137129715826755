const parseMessages = (messages) =>
  messages.map((i) => ({
    sid: i.state.sid,
    author: i.state.author,
    body: i.state.body,
    index: i.state.index,
    timestamp: i.state.timestamp,
  }));

export default parseMessages;
