import style from './Panel.module.scss';

const Panel = ({ children }) => (
  <div className={style.panel}>
    <svg width="69" height="70" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 3)" fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx="30.6" cy="36.2" r="30.6" />
        <circle
          stroke="#007EA2"
          strokeWidth="4.8"
          cx="35.8"
          cy="30.6"
          r="30.6"
        />
        <g fill="#007EA2" fillRule="nonzero">
          <path d="M39.1 17.7a3.3 3.3 0 11-6.6 0 3.3 3.3 0 016.6 0M41.74 44.1H39.1V25.95c0-.99-.66-1.65-1.65-1.65h-7.59c-.33 0-.66.33-.66.66v1.98c0 .33.33.66.66.66h2.64v16.5h-2.64c-.33 0-.66.33-.66.66v1.98c0 .33.33.66.66.66h11.88c.33 0 .66-.33.66-.66v-1.98c0-.33-.33-.66-.66-.66z" />
        </g>
      </g>
    </svg>
    {children}
  </div>
);

export default Panel;
