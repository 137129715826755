const fetchAddressStatus = async () => {
  const res = await fetch('/api/address-status', {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const json = await res.json();

  return json.status;
};

export default fetchAddressStatus;
